.main {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    width: 100%;
    height: 100%;
    margin-top: 60px;
    justify-content: center;
    text-align: center;
    align-items: center;
    display: flex;
    flex-direction: column;
}

.title {
    font-size: 50px;
    text-align: center;
    font-weight: bold;
    color: cadetblue;
}

.subtitle {
    text-align: left;
    margin-top: -20px;
    font-weight: bold;
}

.section {
    width: 80%;
    display: flex;
    flex-direction: column;
    text-align: center;
    margin-bottom: 15px;
}

.sub-title {
    font-size: 25px;
    font-weight: bold;
    text-align: left;
    color: cadetblue;
    margin-top: 20px;
    margin-bottom: 20px;
}

.text {
    text-align: left;
}

.columns {
    display: flex;
    flex-direction: row;
}

.column {
    width: 50%;
    margin-top: 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    
}

.columnss {
    display: flex;
    flex-direction: row;
    text-align: left;
}

.columnn {
    width: 50%;
    display: flex;
    flex-direction: column;
    padding-right: 20px;
}

.column-text {
    text-align: left;
    color: cadetblue;
    font-weight: bold;
}

.column-title {
    font-weight: bold;
    font-size: 18px;
    margin-bottom: 10px;
}

.section-title {
    font-weight: bold;
    font-size: 18px;
    margin-bottom: 10px;
    text-align: left;
}

.sketch {
    max-width: 80%;
    border: 3px solid cadetblue;
    border-radius: 10px;
}

ul {
    margin-top: 0px
}

li {
    text-align: left;
}

li:not(:first-child) {
    padding-top: 10px;
}

.caption {
    margin-top: 20px;
    width: 85%;
    text-align: center;
    align-self: center;
    font-weight: bold;
}

.storyboard {
    max-width: 90%
}

.storyboard-canvas {
    border: 3px solid cadetblue;
    border-radius: 10px;
    margin-top: 15px;
    margin-bottom: 20px;
}